<template>
  <div>
    <!-- asqui lo otro -->
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <!-- Header: Personal Info -->
      <div class="d-flex mb-2" v-if="idRole == 3">
        <feather-icon icon="UserIcon" size="19" />
        <h4 class="mb-0 ml-50">Add a Office</h4>
      </div>
      <b-row v-if="idRole == 3">
        <!-- Field: identification_number -->
        <b-col cols="12" md="4">
          <b-form-group label="Offices" label-for="id_service">
            <v-select
              :options="offices"
              label="label"
              :reduce="(label) => label.value"
              v-model="model.officeId"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Last  Name -->
        <b-col cols="12" md="3">
          <b-form-group label="">
            <b-button
              variant="primary"
              class="mt-2"
              @click="addServiceEmployee()"
            >
              <span class="text-nowrap">Add Office</span>
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
      <!-- Form: Personal Info Form -->

      <!-- Header: Personal Info -->
      <div class="d-flex mt-2">
        <feather-icon icon="MapPinIcon" size="19" />
        <h4 class="mb-0 ml-50">Employee Offices</h4>
      </div>

      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchUsers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Role -->
        <template #cell(percentage_comission)="data">
          <b-form-input
            style="width: 40%"
            id="percentage_comission"
            :key="data.item.id"
            v-model="data.item.percentage_comission"
            @change="updateServiceEmployee(data.item)"
          />
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data" v-if="editOffice">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item>
              <feather-icon icon="TrashIcon" />
              <span
                class="align-middle ml-50"
                @click="confirmDelete(data.item)"
              >
                Delete
              </span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BForm,
  BFormGroup,
  BModal,
  VBModal,
} from "bootstrap-vue";
import vSelect from "vue-select";
import router from "@/router";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";

import useUsersList from "./useEmployeeOfficeList";
import userStoreModule from "../employeeStoreModule";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BForm,
    vSelect,
    BFormGroup,
    BModal,
    VBModal,
  },

  methods: {
    // confirm texrt
    confirmDelete(data) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteEmployeeOffice(data);
          this.$swal({
            icon: "success",
            title: "Deleted!",
            text: "Your file has been deleted.",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      });
    },
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const USER_APP_STORE_MODULE_NAME = "app-user";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const isAddNewUserSidebarActive = ref(false);
    const comissionUpdate = 0;

    const toast = useToast();
    const options = [
      { country: "Canada", code: "CA" },
      { country: "Venezuela", code: "VE" },
      { country: "Colombia", code: "CO" },
    ];
    const prueba = ref("");

    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      idE,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      dataItemStatus,
      dataItemCountries,
    } = useUsersList();

    const roleOptions = dataItemCountries;

    const statusOptions = dataItemStatus;

    const userData = ref(null);
    const offices = ref([]);

    idE.value = router.currentRoute.params.id;

    const name = props.userData.first_name + " " + props.userData.last_name;
    const parser = JSON.parse(localStorage.getItem("userData"));
    const idRole = parser.userData.rolId;

    const model = ref({
      employeId: idE.value,
      status: true,
      name,
    });
    store
      .dispatch("app-user/fetchEmployeesOffices", {
        id: router.currentRoute.params.id,
      })
      .then((response) => {
        userData.value = response.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          userData.value = undefined;
        }
      });

    store
      .dispatch("app-user/fetchOffices")
      .then((response) => {
        const arrayoffices = [];
        arrayoffices.push();
        response.data.data.map((data, item) => {
          if (data.status === true) {
            arrayoffices.push({
              label: data.name,
              value: data.id,
              id: item,
            });
          }
        });

        offices.value = arrayoffices;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          userData.value = undefined;
        }
      });

    const addServiceEmployee = () => {
      store
        .dispatch("app-user/addEmployeeOffice", { model })
        .then((response) => {
          toast({
            component: ToastificationContent,
            props: {
              title: `SUCCESS`,
              icon: "CheckCircleIcon",
              text: `${response.data.msg}`,
              variant: "success",
            },
          });
          refetchData();
        })
        .catch((err) => {
          toast({
            component: ToastificationContent,
            props: {
              title: `ERROR`,
              icon: "AlertOctagonIcon",
              variant: "danger",
              text: `${err}`,
              solid: true,
            },
          });
        });
    };

    return {
      options,
      offices,
      prueba,
      addServiceEmployee,

      comissionUpdate,

      // Sidebar
      isAddNewUserSidebarActive,

      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      idE,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      model,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      algo: 3,
      idRole,

      deleteEmployeeOffice: (data) => {
        const { id, name } = data;
        store
          .dispatch("app-user/deleteEmployeeOffice", { id })
          .then((response) => {
            toast({
              component: ToastificationContent,
              props: {
                title: `The Service was delete correctly`,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
            refetchData();
          });
      },
      editOffice: idRole !== 4 ? true : false,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
