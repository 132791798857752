<template>
  <validation-observer ref="simpleRules">
    <div>
      <!-- asqui lo otro -->
      <!-- Table Container Card -->
      <b-card no-body class="mb-0">
        <!-- Header: Personal Info -->
        <div v-if="editService">
          <div class="d-flex mb-2">
            <feather-icon icon="UserIcon" size="19" />
            <h4 class="mb-0 ml-50">Add a Service</h4>
          </div>

          <b-row>
            <b-col cols="12" md="4">
              <b-form-group
                label="Category Services"
                label-for="id_category_service"
              >
                <v-select
                  :options="categoryServices"
                  label="label"
                  :reduce="(label) => label.value"
                  v-model="category"
                  @input="filterServices()"
                />
              </b-form-group>
            </b-col>
            <!-- Field: identification_number -->
            <b-col cols="12" md="4">
              <b-form-group label="Services" label-for="id_service">
                <v-select
                  :options="services"
                  label="label"
                  :reduce="(label) => label.value"
                  v-model="model.serviceId"
                />
              </b-form-group>
            </b-col>

            <!-- Field: First  Name -->
            <b-col cols="12" md="2">
              <b-form-group
                label="% Comission"
                label-for="percentage_comission"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required|between:1,100"
                  name="Percentage Comission"
                >
                  <b-form-input
                    id="percentage_comission"
                    v-model="model.percentage_comission"
                    type="number"
                    maxlength="4"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Field: Last  Name -->
            <b-col cols="12" md="3">
              <b-form-group label="">
                <b-button
                  variant="primary"
                  class="mt-2"
                  @click="addServiceEmployee()"
                >
                  <span class="text-nowrap">Add Service</span>
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <!-- Form: Personal Info Form -->

        <!-- Header: Personal Info -->
        <div class="d-flex mt-2">
          <feather-icon icon="MapPinIcon" size="19" />
          <h4 class="mb-0 ml-50">Employee Services</h4>
        </div>

        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Show</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>entries</label>
            </b-col>

            <!-- Search -->
            <b-col cols="12" md="6">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Search..."
                />
              </div>
            </b-col>
          </b-row>
        </div>

        <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="fetchUsers"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >
          <!-- Column: Role -->
          <template #cell(percentage_comission)="data">
            <validation-provider
              #default="{ errors }"
              rules="required|between:1,100"
              name="Percentage Comission"
            >
              <b-form-input
                v-if="editService"
                style="width: 40%"
                id="percentage_comission"
                :key="data.item.id"
                v-model="data.item.percentage_comission"
                @change="updateServiceEmployee(data.item)"
              />
              <b-badge
                v-else
                pill
                :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
                class="text-capitalize"
              >
                {{ data.item.percentage_comission }}
              </b-badge>

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data" v-if="editService">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item>
                <feather-icon icon="TrashIcon" />
                <span
                  class="align-middle ml-50"
                  @click="confirmDelete(data.item)"
                >
                  Delete
                </span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">
                Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
                {{ dataMeta.of }} entries
              </span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalUsers"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>

        <!-- modal -->
        <b-modal
          id="modal-xs"
          cancel-variant="secondary"
          ok-only
          ok-title="Accept"
          centered
          size="xs"
          title="Extra Small Modal"
        >
          <b-card-text>
            Biscuit chocolate cake gummies. Lollipop I love macaroon bear claw
            caramels. I love marshmallow tiramisu I love fruitcake I love gummi
            bears. Carrot cake topping liquorice. Pudding caramels liquorice
            sweet I love. Donut powder cupcake ice cream tootsie roll jelly.
          </b-card-text>
        </b-modal>
      </b-card>
    </div>
  </validation-observer>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BForm,
  BFormGroup,
  BModal,
  VBModal,
} from "bootstrap-vue";
import vSelect from "vue-select";
import router from "@/router";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";

import useUsersList from "./useEmployeeServiceList";
import userStoreModule from "../employeeStoreModule";

// Notification
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from "@validations";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BForm,
    vSelect,
    BFormGroup,
    BModal,
    VBModal,
    ValidationProvider,
    ValidationObserver,
  },

  methods: {
    // confirm texrt
    confirmDelete(data) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteEmployeeServices(data);
          this.$swal({
            icon: "success",
            title: "Deleted!",
            text: "Your file has been deleted.",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      });
    },
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },

  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-user";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const isAddNewUserSidebarActive = ref(false);
    const comissionUpdate = 0;

    const toast = useToast();
    const options = [
      { country: "Canada", code: "CA" },
      { country: "Venezuela", code: "VE" },
      { country: "Colombia", code: "CO" },
    ];
    const prueba = ref("");

    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      idE,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      dataItemStatus,
      dataItemCountries,
    } = useUsersList();

    const validations = ref({
      required,
      confirmed,
      password,
      email,
      min,
      integer,
      url,
      alpha,
      between,
      digits,
      length,
      alphaDash,
    });

    const roleOptions = dataItemCountries;

    const statusOptions = dataItemStatus;
    const simpleRules = ref(null);

    const userData = ref(null);
    const categoryServices = ref([]);
    const services = ref([]);
    const category = ref("");
    const parser = JSON.parse(localStorage.getItem("userData"));
    const idRole = parser.userData.rolId;

    const idOffice = parser.dataUser.officeEmployee_officeId;
    const idRol = parser.userData.rolId;

    idE.value = router.currentRoute.params.id;

    const model = ref({
      employeeId: idE.value,
      status: true,
      category,
    });
    store
      .dispatch("app-user/fetchEmployeesServices", {
        id: router.currentRoute.params.id,
      })
      .then((response) => {
        userData.value = response.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          userData.value = undefined;
        }
      });

    store
      .dispatch("app-user/fetchCategoryServices", { idOffice, idRol })
      .then((response) => {
        const arrayCatgoryServices = [];
        response.data.data.map((data, item) => {
          if (data.status === true) {
            arrayCatgoryServices.push({
              label: data.name,
              value: data.categoryId,
              id: item,
            });
          }
        });
        categoryServices.value = arrayCatgoryServices;
      })
      .catch((error) => {
        console.log(error);
      });

    const filterServices = () => {
      store
        .dispatch("app-user/fetchServices")
        .then((response) => {
          console.log("Que tiene esto ", category.value);
          const serviceFilter = response.data.data.filter(function (f) {
            return f.id_category === category.value;
          });
          const arrayServices = [];
          arrayServices.push({
            label: "All",
            value: 0,
          });
          console.log("que trae ....", serviceFilter);
          serviceFilter.map((data, item) => {
            arrayServices.push({
              label: data.name,
              value: data.id,
              id: item,
            });
          });
          services.value = arrayServices;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            userData.value = undefined;
          }
        });
    };

    const addServiceEmployee = () => {
      simpleRules.value.validate().then((success) => {
        if (success) {
          store
            .dispatch("app-user/addServiceEmployee", { model })
            .then((response) => {
              console.log("RESPONSE=>", { response });
              setTimeout(() => {
                toast({
                  component: ToastificationContent,
                  props: {
                    title: `SUCCESS`,
                    icon: "CheckCircleIcon",
                    text: `${response.data.msg}`,
                    variant: "success",
                  },
                });
                refetchData();
              }, 1500);
            })
            .catch((err) => {
              toast({
                component: ToastificationContent,
                props: {
                  title: `ERROR`,
                  icon: "AlertOctagonIcon",
                  variant: "danger",
                  text: `${err}`,
                  solid: true,
                },
              });
            });
        }
      });
    };
    const updateServiceEmployee = (data) => {
      simpleRules.value.validate().then((success) => {
        if (success) {
          store
            .dispatch("app-user/updateEmployeeServices", data)
            .then((response) => {
              console.log("RESPONSE=>", { response });
              toast({
                component: ToastificationContent,
                props: {
                  title: `SUCCESS`,
                  icon: "CheckCircleIcon",
                  text: `${response.data.msg}`,
                  variant: "success",
                },
              });
              refetchData();
            })
            .catch((err) => {
              toast({
                component: ToastificationContent,
                props: {
                  title: `ERROR`,
                  icon: "AlertOctagonIcon",
                  variant: "danger",
                  text: `${err}`,
                  solid: true,
                },
              });
            });
        }
      });
    };

    return {
      options,
      filterServices,
      categoryServices,
      category,
      services,
      prueba,

      addServiceEmployee,
      updateServiceEmployee,
      comissionUpdate,
      simpleRules,

      // Sidebar
      isAddNewUserSidebarActive,

      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      idE,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      model,
      services,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      algo: 3,
      idRole,
      validations,

      deleteEmployeeServices: (data) => {
        const { id, name } = data;
        store
          .dispatch("app-user/deleteEmployeeServices", { id })
          .then((response) => {
            toast({
              component: ToastificationContent,
              props: {
                title: `The Service was delete correctly`,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
            refetchData();
          });
      },
      editService: idRole !== 4 ? true : false,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
