<template>
  <validation-observer ref="simpleRules">
    <div>
      <div class="d-flex mb-2 mt-2">
        <feather-icon icon="ImageIcon" size="19" />
        <h4 class="mb-0 ml-50">Photo (Max Size 2Mb)</h4>
      </div>
      <!-- Media -->
      <b-media class="mb-2">
        <template #aside>
          <b-avatar ref="previewEl" :src="userData.photo" size="90px" rounded />
        </template>
        <div class="d-flex flex-wrap">
          <b-button variant="primary" @click="$refs.refInputEl.click()">
            <input
              ref="refInputEl"
              type="file"
              class="d-none"
              @change="uploadImage"
            />
            <span class="d-none d-sm-inline">Update</span>
            <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
          </b-button>
        </div>
      </b-media>

      <!-- Header: Personal Info -->
      <div class="d-flex mb-2 mt-2">
        <feather-icon icon="UserIcon" size="19" />
        <h4 class="mb-0 ml-50">Personal Information</h4>
      </div>

      <!-- User Info: Input Fields -->
      <b-form>
        <b-row>
          <!-- Field: identification_number -->
          <b-col cols="12" md="4">
            <b-form-group label="Identification Number" label-for="idN">
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Identification Number"
              >
                <b-form-input
                  id="idN"
                  v-model="userData.identification_number"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Field: First  Name -->
          <b-col cols="12" md="4">
            <b-form-group label="First Name" label-for="firstName">
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="First Name"
              >
                <b-form-input
                  id="firstName"
                  v-model="userData.first_name"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Last  Name -->
          <b-col cols="12" md="4">
            <b-form-group label="Last Name" label-for="lastName">
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Last Name"
              >
                <b-form-input
                  id="lastName"
                  v-model="userData.last_name"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Email -->
          <b-col cols="12" md="4">
            <b-form-group label="Email" label-for="email">
              <validation-provider
                #default="{ errors }"
                rules="required|email"
                name="Email"
              >
                <b-form-input
                  id="email"
                  v-model="userData.mail"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Phone Number -->
          <b-col cols="12" md="4">
            <b-form-group label="Phone Number" label-for="phoneNumber">
              <validation-provider
                #default="{ errors }"
                rules="required|integer"
                name="Phone Number"
              >
                <b-form-input
                  id="phoneNumber"
                  v-model="userData.phone_number"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Birth Date-->
          <b-col cols="12" md="4">
            <b-form-group label="Date of Birth1" label-for="birthDate">
              <div>
                <b-input-group>
                  <flat-pickr
                    v
                    class="form-control"
                    v-model="userData.birth_date"
                    placeholder=""
                  />
                </b-input-group>
              </div>
            </b-form-group>
          </b-col>

          <!-- Field: Role -->
          <b-col cols="12" md="4">
            <b-form-group label="City" label-for="city">
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="City"
              >
                <v-select
                  :options="cityOptions"
                  v-model="userData.cityId"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  input-id="city"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Status -->
          <b-col cols="12" md="4">
            <b-form-group label="Status" label-for="user-status">
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                v-model="userData.status"
                input-id="user-status"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Adress-->
          <b-col cols="12" md="4">
            <b-form-group label="Address" label-for="adress">
              <b-form-textarea
                id="adress"
                v-model="userData.adress"
                rows="2"
                max-rows="8"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-col cols="12" md="4" class="mb-2">
          <b-form-checkbox
            v-model="userData.is_access"
            name="check-button"
            switch
            inline
          >
            Is Access
          </b-form-checkbox>
        </b-col>

        <!-- Aqu se comienza la validacion de password -->

        <div v-show="userData.is_access">
          <b-row>
            <!-- Field: User  Name -->

            <b-col cols="12" md="4">
              <b-form-group label="User Name" label-for="userName">
                <validation-provider
                  v-if="userData.is_access"
                  #default="{ errors }"
                  rules="required"
                  name="User Name"
                >
                  <b-form-input
                    v-if="withUserName"
                    id="userName"
                    v-model="userLogin.user_name"
                    :state="errors.length > 0 ? false : null"
                    disabled
                  />
                  <b-form-input
                    v-else
                    id="userName"
                    v-model="userLogin.user_name"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="4">
              <b-form-group label="Rol" label-for="rol">
                <validation-provider
                  v-if="userData.is_access"
                  #default="{ errors }"
                  rules="required"
                  name="Rol"
                >
                  <v-select
                    :options="rolsOptions"
                    v-model="userLogin.rolId"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    input-id="rol"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Field: Password -->
            <b-col cols="12" md="3">
              <b-form-group label="Password" label-for="password">
                <validation-provider
                  v-if="userData.is_access"
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                  :disabled="valid == 1"
                >
                  <b-form-input
                    id="password"
                    v-model="userLogin.password2"
                    type="password"
                    :state="errors.length > 0 ? false : null"
                    :disabled="valid == 1"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" class="mt-2" md="1">
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                @click.prevent="valid = (valid + 1) % 2"
              >
                <feather-icon icon="RefreshCwIcon" size="16" />
              </b-button>
            </b-col>
          </b-row>
        </div>

        <!-- Fin del crear usuario -->
      </b-form>

      <!-- Action Buttons -->
      <b-button
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        @click.prevent="validationForm"
      >
        Update
      </b-button>
    </div>
  </validation-observer>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormTextarea,
  BFormDatepicker,
  BInputGroupPrepend,
  BInputGroupAppend,
  BInputGroup,
} from "bootstrap-vue";

import vSelect from "vue-select";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import flatPickr from "vue-flatpickr-component";
import router from "@/router";
import store from "@/store";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from "@validations";

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    BFormTextarea,
    BFormDatepicker,
    ValidationProvider,
    ValidationObserver,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroup,
    flatPickr,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const toast = useToast();
    const validations = ref({
      required,
      confirmed,
      password,
      email,
      min,
      integer,
      url,
      alpha,
      between,
      digits,
      length,
      alphaDash,
    });

    const imgAvatar = ref("");

    const cityOptions = ref([]);

    const simpleRules = ref(null);
    const maxDate = ref(new Date());
    const rolsOptions = ref([]);
    const userLogin = ref({});
    const withUserName = ref(null);

    const statusOptions = [
      { label: "Active", value: true },
      { label: "Inactive", value: false },
    ];
    const valid = ref(1);
    const parser = JSON.parse(localStorage.getItem("userData"));
    const idRole = parser.userData.rolId;
    const a = [];
    store
      .dispatch("app-user/fetchHelpers")
      .then((response) => {
        const { cities } = response.data.data;

        cities.map((data, item) => {
          a.push({ label: data.name, value: data.id, id: item });
        });

        cityOptions.value = a;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          userData.value = undefined;
        }
      });
    if (props.userData.is_access) {
      store
        .dispatch("app-user/fetchUserLogin", {
          id: props.userData.userId,
        })
        .then((response) => {
          userLogin.value = response.data;
          userLogin.value.password2 = "12345678";
          withUserName.value = response.data.user_name;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            //userLogin.value = undefined;
          }
        });
    }
    const b = [];

    store
      .dispatch("app-user/fetchHelpers")
      .then((response) => {
        const { rols } = response.data.data;

        rols.map((data, item) => {
          if (idRole === 2 || idRole === 3) {
            b.push({ label: data.name, value: data.id, id: item });
          } else {
            if (idRole === data.id) {
              b.push({ label: data.name, value: data.id, id: item });
            }
          }
        });
        rolsOptions.value = b;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          userData.value = undefined;
        }
      });

    const uploadImage = (e) => {
      let file = e.target.files[0];
      if (file.size > 2097152) {
        return toast({
          component: ToastificationContent,
          props: {
            title: `ERROR`,
            icon: "AlertOctagonIcon",
            variant: "danger",
            text: `image exceeds the allowed weight 2Mb`,
            solid: true,
          },
        });
      }
      Object.assign(props.userData, { file: file });
      viewImage(file);
    };

    const viewImage = (image) => {
      let reader = new FileReader();
      reader.onload = (e) => {
        props.userData.photo = e.target.result;
      };
      reader.readAsDataURL(image);
    };

    const validationForm = () => {
      simpleRules.value.validate().then((success) => {
        if (success) {
          console.log("vamos guardar el DATAAA->", userLogin.value.password2);

          let formData = new FormData();
          formData.append("id", props.userData.id);
          formData.append("first_name", props.userData.first_name);
          if (props.userData.file) {
            formData.append("file", props.userData.file);
          }

          formData.append("adress", props.userData.adress);
          formData.append("birth_date", props.userData.birth_date);
          formData.append("cityId", props.userData.cityId);
          formData.append("id_user", props.userData.id_user);
          formData.append(
            "identification_number",
            props.userData.identification_number
          );
          formData.append("last_name", props.userData.last_name);
          formData.append("mail", props.userData.mail);
          formData.append("phone_number", props.userData.phone_number);
          formData.append("status", props.userData.status);
          formData.append("is_access", props.userData.is_access);
          formData.append("user_name", userLogin.value.user_name);
          formData.append("idUser", userLogin.value.id);
          formData.append("rolId", userLogin.value.rolId);
          formData.append("type", 1);
          formData.append("idOffice", parser.dataUser.officeEmployee_officeId);
          formData.append("idRol", idRole);
          if (userLogin.value.password2 != 12345678) {
            formData.append("password", userLogin.value.password2);
          }
          // eslint-disable-next-line
          store
            .dispatch("app-user/updateEmployee", formData)
            .then((response) => {
              toast({
                component: ToastificationContent,
                props: {
                  title: `SUCCESS`,
                  icon: "CheckCircleIcon",
                  text:
                    idRole !== 4
                      ? `${response.data.msg}`
                      : "Data updated successfully",

                  variant: "success",
                },
              });
              idRole === 4 ? "" : router.push({ name: "apps-employee-list" });
            })
            .catch((err) => {
              toast({
                component: ToastificationContent,
                props: {
                  title: `ERROR`,
                  icon: "AlertOctagonIcon",
                  variant: "danger",
                  text: `${err}`,
                  solid: true,
                },
              });
            });
        }
      });
    };

    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {}
    );

    return {
      cityOptions,
      statusOptions,
      validations,
      uploadImage,
      imgAvatar,
      simpleRules,
      validationForm,
      rolsOptions,
      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
      maxDate,
      userLogin,
      idRole,
      valid,
      withUserName,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
